import React from 'react'
import Layout from '../../components/Default/Layout'

const DekujemeVam = () => {
  return (
    <Layout sClick={`var seznam_cId = 100069217; var seznam_value = 0;`}>
      <div className="DekujemeVam">
        <h1>Thank you for message</h1>
        <a href="/" className="Button">
          Homepage
        </a>
      </div>
    </Layout>
  )
}

export default DekujemeVam
